import { attributionTypes } from "src/redux/slices/attributions";

export interface Message {
  id: string;
  text: string;
  timestamp: string; //ISO string
  sender: string;
  isGuest: boolean;
  avatar: string;
}

export interface Thread {
  threadId: string;
  guestName: string;
  guestAvatar: string;
  thread: Message[];
  lastMessageTimestamp: string; //ISO string
  pms: string;
  hostingService: string;
  draft: string;
  isAutopilot: boolean;
  reservationId: string;
  reservationStart: string;
  reservationEnd: string;
  listingName: string;
  reservationStatus: string;
  umId: string; // unanswered message ID
  sentiment: number;
  summary: string;
}

export interface SendMessageRequest {
  conversationId: string;
  text: string;
  userToken: string;
}

export interface GetThreadsResponse {
  error: boolean;
  count: number;
  threads: Thread[] | null;
}

export interface GetThreadRequest {
  userToken: string;
  hostaway_conversation_id: string;
}

export interface GetThreadResponse {
  error: boolean;
  thread: Thread | null;
}

export interface GetThreadsRequest {
  userToken: string;
  page: number;
  paginationLimit: number;
  offset: number;
  messagesToFetch: "Unanswered" | "All" | "Archived" | "Autopilot";
  all: boolean;
  listingFilter: {
    individualListingIds: string[];
    listingSetIds: string[];
  };
}

export interface GetListingIdMatchesRequest {
  userToken: string;
  listingId: string;
}

export interface GetListingIdMatchesResponse {}
export interface FetchPrefDetailsRequest {
  userToken: string;
  prefId: string;
}

export interface FetchPrefDetailsResponse {}

export interface RemoveThreadRequest {
  userToken: string;
  threadId: string;
}

export interface GetUserInfoRequest {
  userToken: string;
}

export interface GetUserInfoResponse {
  username: string; // their email
  name: string; // their name
  createdAt: string; // ISO string
  avatar: string;
  unansweredMessages: number; // we can get this from GetThreads or GetUserInfo
  plan: string;
  period: string;
  trialEnd: string; // ISO string
  showMessagePopup: boolean;
  trialHasPassed: boolean;
  pastDue: boolean;
  isFirstTime: boolean;
  isFirstTimeListingsReady: boolean;
  userToken: string;
  userInviteToken: string;
  pms: string;
  perUserPrompts: string;
  neverEscalateDraftMode: boolean;
  offerLanguage: string;
  summaryLanguage: string;
  autopilotSignature: string | null;
  autoEnableListings: boolean;
  extensionInstalledAt: string | null;
  webAppCredsIsExpired: boolean;
  templateFirstName: string | null;
  numListings: number;
  widgetEligible: boolean;
  widgetEnabled: boolean;
  stripeCustomerId: string | null;
  churnkeyHash: string | null;
  stripeIntentSecret: string | null;
  listingSetsEnabled: boolean;
  connectedStripeAccountInfo: string | null;
  savedPaymentMethod: boolean | null;
  numListingsPaidFor: number;
  bffForwardCallsTo: string | null;
  bffReadyToSeeApp: boolean;
  isBffLive: boolean;
}

export interface AuthUserRequest {
  userToken: string;
}

export enum LoginStatus {
  ValidUserCanLogin = 1,
  ValidUserCannotLogin = 2,
  UserNotLoggedIn = 3,
  ValidUserNoPMSInfo = 4,
  EscapiaUserWhoHasClickedISubmittedATicketButton = 5,
}

export function describeLoginStatus(loginStatus: LoginStatus) {
  switch (loginStatus) {
    case LoginStatus.ValidUserCanLogin:
      return "Valid user can login";
    case LoginStatus.ValidUserCannotLogin:
      return "Valid user cannot login";
    case LoginStatus.UserNotLoggedIn:
      return "User not logged in";
    case LoginStatus.ValidUserNoPMSInfo:
      return "Valid user no PMS info";
    case LoginStatus.EscapiaUserWhoHasClickedISubmittedATicketButton:
      return "Escapia user who has clicked I submitted a ticket button";
  }
}

export interface AuthUserResponse {
  userId: number;
  loginStatus: LoginStatus;
  admin: boolean;
  permissions?: RegularUserPermissions;
}

export interface SetSavedPaymentMethodRequest {
  userToken: string;
  savedPaymentMethod: boolean;
}

export interface ListingMessage {
  id: string;
  text: string;
  sender: string; // "host" | "besty"
  escalated: boolean;
}

export interface Listing {
  id: string;
  name: string;
  enabled: boolean;
  embedded: boolean;
  thread: ListingMessage[];
  avatar: string;
}

export interface GetListingsResponse {
  listings: Listing[];
  firstTime: boolean;
}

export interface GetListingsRequest {
  userToken: string;
}
export interface EnableListingsRequest {
  userToken: string;
  listingIds: string[];
}
export interface DisableListingsRequest {
  userToken: string;
  listingIds: string[];
}

export interface GetHostNotesRequest {
  userToken: string;
}

export interface GetHostNotesResponse {
  hostNotes: HostNote[];
}

export interface UpdateHostNotesRequest {
  method: string;
  userToken: string;
  hostNote: HostNote;
}
export interface UpdateHostNotesResponse {}

export interface GetHostNoteAiResponseRequest {
  userToken: string;
  note: string;
}
export interface GetHostNoteAiResponseResponse {
  invalid: string[];
  data_points: string[];
  upsell_items: string[];
}

export interface GetEarlyCheckinUpsellsRequest {
  userToken: string;
}

export interface GetEarlyCheckinUpsellsResponse {
  earlyCheckinUpsells: EarlyCheckinUpsell[];
}

export interface UpdateEarlyCheckinUpsellsRequest {
  method: string;
  userToken: string;
  earlyCheckinUpsell: EarlyCheckinUpsell;
}
export interface UpdateEarlyCheckinUpsellsResponse {}

export interface AskQuestionRequest {
  userToken: string;
  listingId: string;
  message: string;
}

export interface AskQuestionResponse {
  message: ListingMessage;
}

export interface GetAnalyticsRequest {
  userToken: string;
  from: string;
  to: string;
}
interface TimeSeriesData {
  [key: number]: number;
}

export interface AnalyticsData {
  bloom: TimeSeriesData[];
  host: TimeSeriesData[];
}
export interface ListingsData {
  live: number;
  total: number;
}

export interface ResponseTimeData {
  impact: number;
  bestyResponse: number;
  manualResponse: number;
}

export interface ListingResponse {
  id: string;
  name: string;
  data: TimeSeriesData[];
}
export interface MessageChain {
  id: string;
  fromAvatar: string;
  bestyAvatar: string;
  fromMessage: string;
  bestyMessage: string;
  fromTimestamp: string;
  bestyTimestamp: string;
}

export interface OrphanData {
  offersSent: number;
  daysBooked: number;
  revenue: number;
}

export interface GetAnalyticsResponse {
  analytics: {
    messagesSent: AnalyticsData;
    listings: ListingsData;
    responseTime: ResponseTimeData;
    listingResponseTimes: ListingResponse[];
    recentMessages: MessageChain[];
    orphanStats: OrphanData;
  };
}

// -- notification settings, which we just call Settings --
export interface Settings {
  id: number;
  notification_type: string;
  // sms, email, or whatsapp
  notification_to: string;
  // the number/email

  /*
    phone: string;
    email: string;
    method: string;
  */
}
export interface UpdateSettingsRequest {
  userToken: string;
  settings: Object;
}
export interface UpdateSettingsResponse {}
export interface GetSettingsRequest {
  userToken: string;
}
export interface GetSettingsResponse {
  settings: Settings[];
}

export interface GetListingDataPointsRequest {
  listingId: string;
  userToken: string;
}
export interface GetListingDataPointsResponse {
  dataPoints: string[];
}

// --widget settings

export interface WidgetSettings {
  enabled: boolean;
  coolOffPeriod: number;
  dbsEngine: string;
  collectInfoMode: string;
  slackMode: string;
  bestyAIRefer: string;
  hostRefer: string;
  welcomeMessage: string;
  escalateMessage: string;
  fetched: boolean;
}
export interface UpdateWidgetSettingsRequest {
  userToken: string;
  widgetSettings: WidgetSettings;
}
export interface UpdateWidgetSettingsResponse {}
export interface GetWidgetSettingsRequest {
  userToken: string;
}
export interface GetWidgetSettingsResponse {
  widgetSettings: WidgetSettings;
}

// -- autopilot settings --
export interface AutopilotSettings {
  listingOption: string;
  prefix: string;
  prefixEnabled: boolean;
  cooloff: number;
  enabled: boolean;
  is247: boolean;
  isAllListings: boolean;
  lowerTime: string;
  upperTime: string;
  isWeekendMode: boolean;
  weekdayLowerTime: string;
  weekdayUpperTime: string;
  weekendLowerTime: string;
  weekendUpperTime: string;
  listings: number[];

  guestMode: string;
  escalateMessage: string;
  escalateMessageEnabled: boolean;
  escalateMessageSequence: string[];
  escalateMessageSequenceEnabled: boolean;
  prefixMode: string;
  delay: number;
  //inquiriesOnly: boolean;
  fetched: boolean;
  rules: AutopilotRule[];
}
export interface AutopilotRule {
  id: string;
  name: string;
  listingSetIds: string[];
  config: {
    prefix: string;
    prefixEnabled: boolean;
    cooloff: number;
    enabled: boolean;
    is247: boolean;
    isAllListings: boolean;
    lowerTime: string;
    upperTime: string;
    isWeekendMode: boolean;
    weekdayLowerTime: string;
    weekdayUpperTime: string;
    weekendLowerTime: string;
    weekendUpperTime: string;
    listings: number[];
    guestMode: string;
    escalateMessage: string;
    escalateMessageEnabled: boolean;
    escalateMessageSequence: string[];
    escalateMessageSequenceEnabled: boolean;
    prefixMode: string;
    delay: number;
    fetched: boolean;
  };
}
export interface UpdateAutopilotSettingsRequest {
  userToken: string;
  autopilotSettings: AutopilotSettings;
}
export interface UpdateAutopilotSettingsResponse {}
export interface GetAutopilotSettingsRequest {
  userToken: string;
}
export interface GetAutopilotSettingsResponse {
  autopilotSettings: AutopilotSettings;
}

interface EciLcoSubSettings {
  // Radio button: "When a guest requests an [early check-in, late check-out], and there is a same day turn: "
  //    "Reject their request"
  //    "Escalate their request to the host"
  if_same_day_mode: "reject" | "escalate";

  // Radio button: "When a guest requests an [early checkin, late checkout], and there is no same day turn: "
  //    "Reject their request"
  //    "Enable the [early checkin, late checkout] request"
  //    "Escalate the [early checkin, late checkout] request to the host"
  if_no_same_day_mode: "reject" | "enable" | "escalate";

  // if if_no_same_day_mode is "enable", then all of the below options should show. Else, they should be hidden.

  // Title: "Lower bound"
  lower_bound: number; // "What is the the threshold where you'd like to place a hold versus instantly accept the early checkin request"
  lower_bound_unit: string; // must be either: "days", "hours"

  // Title: "Upper bound"
  upper_bound: number; // "For last minute requests (ex: same day bookings), what's the latest possible time you'd allow an early checkin?"
  upper_bound_unit: string; // must be either: "days", "hours"

  // Title: "Nights of the week"
  // Text: "Select the nights of the week where [early checkins/late checkouts] are offered."
  // (note this is multi-select, and if none of them are selected, show "All nights", which means that nights_to_offer is empty)
  nights_to_offer: string[]; // multi-select ("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday")
  // can be null, which means "All nights"

  // Title: "Monetization"
  monetize_min_amount_percent: number; // The baseline percent amoun
  monetize_min_amount_fixed: number; // Optional, the amount to min that with

  // Title: "Proactively send offers (recommended)"
  is_proactive: boolean; // "Proactively send a message offering an early checkin to guests." (checkbox)

  // if is_proactive is true, then the following options should show. Else, they should be hidden.
  send_offer_message_at: number; // "How long before the guest's arrival would you like to send an offer message?"
  send_offer_message_at_unit: string; // must be either: "days", "hours"
  offer_message: string; // "What would you like to say in the offer message?" (Textarea)
}

export interface EciLcoSettings {
  isAllListings: boolean;
  listingSetIds: string[];
  eciOptions: EciLcoSubSettings; // early checkin options
  lcoOptions: EciLcoSubSettings; // late checkout options
  isEnabled: boolean;
}

export interface UpdateEciLcoSettingsRequest {
  userToken: string;
  eciLcoSettings: EciLcoSettings[];
}
export interface UpdateEciLcoSettingsResponse {}
export interface GetEciLcoSettingsRequest {
  userToken: string;
}
export interface GetEciLcoSettingsResponse {
  eciLcoSettings: EciLcoSettings[];
}

export interface AIMessageSettings {
  lengthChoice: number;
  formalityChoice: number;
  stylingUserPrompt: string;
  //escalatingUserPrompt: string; // not in there now
}
export interface GetAIMessageSettingsRequest {
  userToken: string;
}
export interface GetAIMessageSettingsResponse {
  aiMessageSettings: AIMessageSettings;
}
export interface UpdateAIMessageSettingsRequest {
  userToken: string;
  aiMessageSettings: AIMessageSettings;
}
export interface UpdateAIMessageSettingsResponse {
  success: boolean;
}

// ----- BROADCAST -----
export interface SendBroadcastRequest {
  userToken: string;
  message: string;
  reservationIds: string[];
}
export interface SendBroadcastResponse {}

export interface GetPreviousBroadcastsRequest {
  userToken: string;
}
export interface GetPreviousBroadcastsResponse {
  previousBroadcasts: {
    id: string;
    guest_count: number;
    message: string;
    created_at: string;
  }[];
}

export interface GetReservationsForBroadcastRequest {
  userToken: string;
  channels: string[];
  listingSetIds: string[];
  listingIds: string[];
  listingOption: string;
  reservationStatus: string;
  guestStatus: string | null;
}
export interface GetReservationsForBroadcastResponse {
  reservations: {
    id: string;
    guest_name: string;
    arrival_date: Date;
    departure_date: Date;
  }[];
}

export interface GetReservationsForBroadcastRequest {
  userToken: string;
  channels: string[];
  listingSetIds: string[];
  listingIds: string[];
  listingOption: string;
  reservationStatus: string;
  guestStatus: string | null;
}
export interface GetReservationsForBroadcastResponse {
  reservations: {
    id: string;
    guest_name: string;
    arrival_date: Date;
    departure_date: Date;
  }[];
}

// -- listing sets settings --
export interface ListingSet {
  id: string;
  name: string;
  listingIds: string[];
}
export interface ListingSetsSettings {
  listingSets: ListingSet[];
  enabled: boolean;
}
export interface UpdateListingSetsSettingsRequest {
  userToken: string;
  listingSetsSettings: ListingSetsSettings;
}
export interface UpdateListingSetsSettingsResponse {}
export interface GetListingSetsSettingsRequest {
  userToken: string;
}
export interface ImportGuestyTagsRequest {
  userToken: string;
}
export interface ImportGuestyTagsResponse {}
export interface GetListingSetsSettingsResponse {
  listingSetsSettings: ListingSetsSettings;
}

// -- orphan nights settings --
export interface OrphanNightsRule {
  id: string;
  name: string;
  listingSetIds: string[];
  config: {
    gapLength: number;
    weeknightsOnly: boolean;
    nightlyDiscount: number;
    templateText: string;
    templateTextBookend: string;
    messageDelay: number;
    messageDelayType: string;
    channelRates: object;
    channelRatesEnabled: boolean;
    messageDelayPrev: number;
    messageDelayPrevType: string;
    messageDelayPost: number;
    messageDelayPostType: string;
    orphanMode: string;
    isAllListings: boolean;
    listings: string[];
    templateSubject: string;
    fetched: boolean;
    restrictChannelsEnabled: false;
    restrictChannels: string[] | null; // null means all channels
  };
}
export interface OrphanNightsSettings {
  enabled: boolean;
  //oneNightGap: boolean;
  gapLength: number;
  weeknightsOnly: boolean;
  nightlyDiscount: number;
  templateText: string;
  templateTextBookend: string;
  // note: clientDashboard returns either templateText or templateTextBookend depending on the mode
  messageDelay: number;
  messageDelayType: string;
  channelRates: object;
  channelRatesEnabled: boolean;
  messageDelayPrev: number;
  messageDelayPrevType: string;
  messageDelayPost: number;
  messageDelayPostType: string;
  orphanMode: string;
  isAllListings: boolean;
  listings: string[];
  templateSubject: string;
  fetched: boolean;
  restrictChannelsEnabled: boolean;
  restrictChannels: string[] | null;
  rules: OrphanNightsRule[];
  listingOption: string;
}
export interface UpdateOrphanNightsSettingsRequest {
  userToken: string;
  orphanNightsSettings: OrphanNightsSettings;
}
export interface UpdateOrphanNightsSettingsResponse {}
export interface GetOrphanNightsSettingsRequest {
  userToken: string;
}
export interface GetOrphanNightsSettingsResponse {
  orphanSettings: OrphanNightsSettings;
}

export interface ReviewRequestSettings {
  enabled: boolean;
  hours_before_departure: number;
  is_before: boolean;
  template: string;
  hostaway_listing_id: string | null;
  fetched?: boolean;
  send_false_message: boolean;
  false_message_template: string;
}

export interface LoginInfo {
  email: string;
  password: string;
}

export interface LoginResponse {
  userToken: string;
  loginStatus: number;
}

export interface SignupInfo {
  name: string;
  phone: string;
  email: string;
  password: string;
  inviteCode: string;
  inviteToken: string | null;
}

type SignupResponseFailed = {
  success: false;
  message: string;
};
type SignupResponseSuccess = {
  success: true;
  userId: number;
  userToken: string;
  nonHostUser: boolean;
};
export type SignupResponse = SignupResponseFailed | SignupResponseSuccess;

export interface GetUpgradeQuoteRequest {
  userToken: string;
  period: string; // "monthly" or "yearly"
}

export interface GetQuoteRequest {
  userToken: string;
  quantity: number;
  period: string; // "monthly" or "yearly"
}

export interface UpdateStripePlanRequest {
  userToken: string;
  plan: string;
}

export interface UpdateStripePlanResponse {}

export interface GetQuoteResponse {
  quantity: number;
  subtotal: number;
  credit: number;
  discount: number;
  total: number;
  inviteCode: string | null;
}

export interface HostNoteAiResponse {
  // the responses from the AI, in the 3 categories: invalid, data_points, upsell_items
  invalid: string[];
  data_points: string[];
  upsell_items: string[];
}

export interface HostNote {
  id: string;
  listings: string[];
  category: string;
  note: string;
  aiResponse: HostNoteAiResponse;
  allListings: boolean;
  isDraft: boolean;
}

export interface EarlyCheckinUpsell {
  id: string;
  listingIds: string[];
  allListings: boolean;
  sameDayEnabled: boolean;
  sameDayTrigger: "none" | "clean" | "timed";
  sameDayTriggerCleanHoursBefore: number;
  sameDayTriggerTime: Date;
  sameDayMessage: string;
  noSameDayEnabled: boolean;
  noSameDayTrigger: "none" | "hoursBefore";
  noSameDayTriggerHoursBefore: number;
  noSameDayMessage: string;
}

export interface GetTasksRequest {
  userToken: string;
  page: number;
}

export interface GetTasksResponse {
  tasks: Task[];
  hasMore: boolean;
}

export interface UpdateTasksRequest {
  taskId: string;
}
export interface UpdateTasksResponse {}

export interface UpdateTaskRequest {
  userToken: string;
  task: Task;
}
export interface UpdateTaskResponse {}

export interface InquiryWinbackTemplate {
  id: string;
  minsSinceLastMessage: number;
  offeringDiscount: boolean;
  discountPercentage: number;
  templateText: string;
}

export interface GetInquiryWinbackSettingsRequest {
  userToken: string;
}

export interface GetInquiryWinbackSettingsResponse {
  templates: InquiryWinbackTemplate[];
  enabled: boolean;
  eligibleGuests: string;
  hasMaxInquiryLength: boolean;
  maxInquiryDays: number;
  smartMode: boolean;
}

export interface UpdateInquiryWinbackSettingsRequest {
  userToken: string;
  templates: InquiryWinbackTemplate[];
  enabled: boolean;
  eligibleGuests: string;
  hasMaxInquiryLength: boolean;
  maxInquiryDays: number;
  smartMode: boolean;
}
export interface UpdateInquiryWinbackSettingsResponse {}

export interface CopilotThread {
  id: string;
  messages: CopilotMessage[];
  name: string;
  lastUpdated: string;
}

export interface CopilotMessage {
  id: string;
  confirmationId: string | null; // not null if message is a set/send confirmation
  text: string;
  sender: string; // "host" | "besty"
  error: boolean;
}

export interface GetCopilotThreadsRequest {
  userToken: string;
}

export interface GetCopilotThreadsResponse {
  threads: CopilotThread[];
}

export interface AskCopilotRequest {
  userToken: string;
  threadId: string;
  message: string;
}

export interface AskCopilotResponse {
  message: CopilotMessage;
  date: string;
}

export interface CreateCopilotThreadRequest {
  userToken: string;
  name: string;
}

export interface DeleteCopilotThreadRequest {
  threadId: string;
}

export interface SettleConfirmationRequest {
  confirmationId: string;
  accepted: boolean;
}

export interface UpdateGeneralSettingsRequest {
  userToken: string;
  neverEscalateDraftMode: boolean;
  offerLanguage: string;
  summaryLanguage: string;
  autopilotSignature: string | null;
  bffForwardCallsTo: string | null;
}

export interface UpdateGeneralSettingsResponse {}

export interface ReviewResponderSettings {
  reviewResponderAutopilot: boolean;
  reviewResponderAutopilotMinStars: boolean;
}

export interface GetReviewResponderSettingsRequest {
  userToken: string;
}

export interface GetReviewResponderSettingsResponse {
  settings: ReviewResponderSettings;
}
export interface GetReviewsRequest {
  userToken: string;
  page: number;
  pageSize: number;
  replied: boolean;
}
export interface GetReviewsResponse {
  reviews: ReviewResponse[];
}

export interface ReviewResponse {
  userId: string;
  reviewId: string;
  hostawayReservationId: string;
  guestName: string;
  channel: string;
  reviewedAt: Date;
  rating: number;
  denominator: number;
  content: string;
  replyDraft: string;
  replySentAt: Date | null;
  actualReplySent: string | null;
  autopilot: boolean | null;
}

export interface SendReviewReplyRequest {
  userToken: string;
  reviewId: string;
  response: string;
}

export interface RetargetingSettings {
  enabled: boolean;
  radiusKm: number;
  similarPropertiesCount: number;
  sendMessageAfterMonths: number;
  offerCodeHistory: string[];
  template: string;
}
export interface Attribution {
  id: number | string;
  userId: number;
  attributionType: string;
  attributionId: number;
  revenue: number;
  currencyCode: string;
  createdAt: string;
  updatedAt: string;
  accepted: boolean;
  certain: boolean;
  acceptedNights: string[];
  numberOfGuestMessages: number;
  pmsConversationId: string;
  thread: Thread | null;
  otherBookendThread: Thread | null;
}
export interface GetAttributionsRequest {
  userId: number;
}

export interface GetAttributionTableResponse {
  table: { [attributionType in keyof typeof attributionTypes]: { sent: number; accepted: number; revenue: number } };
}

export interface RejectAttributionRequest {
  userToken: string;
  attributionTableId: number;
}

export interface GetAttributionsResponse {
  attributions: Attribution[];
  preapprovalCount: number;
  offerCount: { [attributionType in keyof typeof attributionTypes]: number };
}

export interface GetBillingSettingsRequest {
  userToken: string;
}

export interface GetBillingSettingsResponse {
  autoEnableListings: boolean;
}

export interface UpdateBillingSettingsRequest {
  userToken: string;
  autoEnableListings: boolean;
}

export interface UpdateBillingSettingsResponse {}

export interface UpdateBillingSettingsResponse {}

export interface Sentiment {
  guest_name: string;
  pms: string;
  arrival_date: string;
  departure_date: string;
  status: string;
  property: string;
  hostaway_listing_id: string;
  hostaway_reservation_id: string;
  summary: string | null;
  unanswered_messages_id: string;
  sentiment: 1 | 2 | 3;
}

export interface GetSentimentsRequest {
  userToken: string;
  sort: string;
  sentiment: string;
  status: string;
  page: number;
  pageSize: number;
}

export interface DownloadTasksRequest {
  userToken: string;
}

export interface DownloadTasksResponse {}

export interface GetSentimentsResponse {
  sentiments: Sentiment[];
}

export interface AgentStats {
  pms_agent_id: string;
  email: string;
  full_name: string;
  messages_sent: number;
  avg_response_time: number | null;
}

export interface GetAgentStatsRequest {
  userToken: string;
  start: Date;
  end: Date;
}

export interface GetAgentStatsResponse {
  stats: AgentStats[];
}

export interface FetchUsersRequest {
  userToken: string;
}

export interface FetchUsersResponse {
  users: User[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  // Add any other user properties you need
}

export interface UpdateReviewRequestSettingsRequest {
  userToken: string;
  reviewRequestSettings: ReviewRequestSettings;
}

export interface UpdateReviewRequestSettingsResponse {
  success: boolean;
}

export interface GetReviewRequestSettingsRequest {
  userToken: string;
}

export interface GetReviewRequestSettingsResponse {
  reviewRequestSettings: ReviewRequestSettings;
}

export interface Task {
  id: string;
  title: string;
  description: string;
  userId: string;
  convo_id: string;
  created_at: string;
  updated_at: string;
  reservation_id: string;
  status: string;
  listing_id: string;
}

export interface TimeUpdatesType {
  id: number;
  pms: string;
  userId: number;
  hostawayConversationId: string;
  hostawayReservationId: string;
  timezone: string;
  lastCheckedMessageId: number;
  isLateCheckIn: boolean;
  isEarlyCheckIn: boolean;
  expectedCheckInTime: string;
  checkInDescription: string;
  legalCheckInTime: string;
  checkInMessageIndex: number;
  isLateCheckOut: boolean;
  isEarlyCheckOut: boolean;
  expectedCheckOutTime: string;
  checkOutMessageIndex: number;
  checkOutDescription: string;
  legalCheckOutTime: string;
}

export interface GetTasksResponse {
  tasks: Task[];
}

export interface AutomatedMessage {
  id: number;
  user_id: string;
  journey_id?: number; // Nullable as it's a reference to message_journeys(id)
  message_name: string;
  message_text: string;
  apply_to: string;
  time_delta_before_after_at: string;
  time_delta_in_hours?: number; // Nullable
  time_delta_units?: string; // Nullable
  created_at?: string; // Defaults to now()
}

export interface MessageJourney {
  id: number;
  user_id: string;
  journey_name: string;
  is_active: boolean;

  channel: string[];

  length_of_stay_enabled: boolean;
  length_of_stay_is_less_than: boolean;
  length_of_stay_display_type: string;
  length_of_stay_num_hours: number;

  advanced_notice_enabled: boolean;
  advanced_notice_is_less_than: boolean;
  advanced_notice_display_type: string;
  advanced_notice_num_hours: number;
  listing_set_ids: string[];
  automated_messages: AutomatedMessage[];
}

export interface BtTemplateVarType {
  listing_id: string;
  template_pretty_name: string;
  template_name: string;
  template_value: string;
  applicable_select: number;
  listing_set_id: string | null;
}

export interface BtTemplateVarResponseType {
  templateVars: BtTemplateVarType[];
  pmsVarsDefault: BtTemplateVarType[];
  directBookingUrl: string;
}

export interface TemplateVariable {
  template_pretty_name: string;
  template_name: string;
}

export interface btMobilePwaSavePushNotificationRequest {
  userToken: string;
  subscription: PushSubscription;
}

export interface btMobilePwaSavePushNotificationResponse {
  success: boolean;
}

export interface SendPushNotificationRequest {
  userToken: string;
  notificationConfig: NotificationConfig;
}

export interface NotificationConfig {
  title: string;
  body: string;
  url: string;
  icon: string;
  badge: string;
  tag: string;
  renotify: boolean;
  requireInteraction: boolean;
  actions: { action: string; title: string }[];
  silent: boolean;
  vibrate: number[];
}

export interface ConnectedGmailAccounts {
  email: string;
  id: number;
  isPrimary: boolean;
  isConnected: boolean;
  gmailAppPassword: string;
  provider: string;
}

export interface UpsellItem {
  id: number;
  name: string;
  description: string;
  listingType: "all" | "individual" | "set";
  individualListingId: string;
  listingSetId: string;
  price: number;
  priceType: "flat" | "perNight" | "variable";
  variableItemDescription: string;
  includeInTemplate: boolean;
  remindGuest: boolean;
  reminderTime: number;
  reminderUnit: "hours" | "days";
  reminderPosition: "before" | "after";
  reminderEvent: "booking" | "check-in" | "check-out";
}

export interface GetCustomUpsellsConfigurationRequest {
  userToken: string;
}

export interface GetCustomUpsellsConfigurationResponse {
  upsellItems: UpsellItem[];
}

export interface UpdateCustomUpsellsConfigurationRequest {
  userToken: string;
  upsellItems: UpsellItem[];
}

export interface UpdateCustomUpsellsConfigurationResponse {
  success: boolean;
}

export interface UpdateCheckinoutSettingsRequest {
  userToken: string;
  listingOption: "Individual listings" | "Listing sets" | "All listings";
  listingIds: string[];
  listingSetIds: string[];
  earliestCheckinTime: string;
  latestCheckoutTime: string;
  currentCheckinTime: string;
  currentCheckoutTime: string;
}

export interface UpdateCheckinoutSettingsResponse {
  success: boolean;
}

export interface GetCheckinoutSettingsRequest {
  userToken: string;
  listingId: string;
}

export interface GetCheckinoutSettingsResponse {
  listingOption: string; // 'Individual listings' | 'Listing sets' | 'All listings' | 'Default'
  listingIds: string[];
  listingSetIds: string[];
  currentCheckinTime: string;
  currentCheckoutTime: string;
  earliestCheckinTime: string;
  latestCheckoutTime: string;
}

export interface OutlookCalllbackParams {
  code: string;
  state: string;
  userToken: string;
}
// upsell fees tab
export interface UpsellFee {
  name: string;
  amount: number;
}

export interface UpdateUpsellFeesRequest {
  userToken: string;
  listingOption: "Individual listings" | "Listing sets" | "All listings";
  listingIds: number[];
  listingSetIds: string[];
  fees: UpsellFee[];
  taxRate: number;
}

export interface UpdateUpsellFeesResponse {
  success: boolean;
}

export interface GetUpsellFeesRequest {
  userToken: string;
  listingId: string;
}

export interface GetUpsellFeesResponse {
  listingOption: "Individual listings" | "Listing sets" | "All listings" | "Default";
  listingIds: number[];
  listingSetIds: string[];
  fees: UpsellFee[];
  taxRate: number;
}

// ### Project Homie ###
export interface HomieChatMessage {
  id: number;
  message: string;
  base64Image: string | null;
  createdAt: string;
  type: "chat" | "hostnote" | "resolution" | "guest_message" | "image";
  isUser: boolean; // true if the message is from the user, false if it's from the homie
  senderName: string;
}

export interface HomieIssue {
  id: number;
  name: string;
  description: string;
  isRead: boolean;
  isResolved: boolean;
  createdAt: string;
  listingName: string;
  listingId: string;
  guestName: string;
  checkinDate: string;
  checkoutDate: string;
}

export interface GetHomieChatThreadRequest {
  userToken: string;
  issueId: number;
}

export interface GetHomieChatThreadResponse {
  messages: HomieChatMessage[];
}

export interface SendHomieChatMessageRequest {
  userToken: string;
  message: string;
  issueId: number;
}

export interface SendHomieChatMessageResponse {
  success: boolean;
}

export interface HomieFilter {
  read: boolean;
  resolved: boolean;
  issueName: string;
}

export interface GetHomieIssuesRequest {
  userToken: string;
  filter: HomieFilter;
}

export interface GetHomieIssuesResponse {
  issues: HomieIssue[];
}

export interface ResolveHomieIssueRequest {
  userToken: string;
  issueId: number;
}

export interface ResolveHomieIssueResponse {
  success: boolean;
}

export interface AddHostNoteRequest {
  userToken: string;
  issueId: number;
  note: string;
  listingId: string;
  listingSetId: string;
  allListings: boolean;
}

export interface AddHostNoteResponse {
  success: boolean;
}

export interface ChangeHomieIssueAssigneeRequest {
  userToken: string;
  issueId: number;
  newAssigneeId: string;
}

export interface ChangeHomieIssueAssigneeResponse {
  success: boolean;
}

export interface GetAssignableUsersRequest {
  userToken: string;
}

export interface GetAssignableUsersResponse {
  users: User[];
}

export interface MarkHomieIssueAsReadRequest {
  userToken: string;
  issueId: number;
}

export interface MarkHomieIssueAsReadResponse {
  success: boolean;
}

export interface MarkHomieIssueAsUnreadRequest {
  userToken: string;
  issueId: number;
}

export interface MarkHomieIssueAsUnreadResponse {
  success: boolean;
}

// ### End of Project Homie ###

// Non-Host User Types
export type AllListings = {
  type: "all";
};

export type Listings = {
  type: "listings";
  listing_ids: number[];
};

export type ListingSets = {
  type: "listing_sets";
  listing_set_ids: string[];
};

export type ListingSettings = AllListings | Listings | ListingSets;

export type RegularUserPermissions = {
  can_see_listings: boolean;
  can_see_settings: boolean;
  listings: ListingSettings;
};

export type AdminUser = {
  type: "admin";
  host_id: number; // what we refer to as the user_id
  id: number;
  email: string;
  name: string;
  phone: string;
  state: "active" | "invited";
};

export type RegularUser = {
  type: "regular";
  host_id: number; // what we refer to as the user_id
  id: number;
  email: string;
  name: string;
  phone: string;
  state: "active" | "invited";
  permissions: RegularUserPermissions;
};

export type NonHostUser = AdminUser | RegularUser;

export type InputAdminHostUser = Omit<AdminUser, "state"> & { state: null };
export type InputRegularHostUser = Omit<RegularUser, "state"> & { state: null };
export type InputNonHostUser = InputAdminHostUser | InputRegularHostUser;
